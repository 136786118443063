import * as React from 'react';
import styled from 'styled-components'; //
import { VisuallyHidden } from '../VisuallyHidden';
export const ProgressBar = ({ percentageProgress, screenReaderText }) => {
    const hiddenText = screenReaderText !== null && screenReaderText !== void 0 ? screenReaderText : `${percentageProgress}% complete`;
    return (React.createElement(StyledContainer, null,
        React.createElement(VisuallyHidden, null, hiddenText),
        React.createElement(StyledComplete, { "$progress": percentageProgress })));
};
const StyledComplete = styled.div `
  width: ${({ $progress }) => $progress}%;
  height: 0.8rem;
  border-radius: 0.4rem;
  background: ${({ theme }) => theme.colors.success.dark};
`;
const StyledContainer = styled.div `
  height: 0.8rem;
  width: 100%;
  display: flex;
  border-radius: 0.4rem;
  background: ${({ theme }) => theme.colors.grey.light};
`;
