import React from 'react';
import styled from 'styled-components';
export const List = ({ variant = 'bullet', listArray, className }) => {
    return (React.createElement(StyledList, { "$variant": variant, className: className }, listArray.map((item) => (React.createElement(StyledLi, { key: item }, item)))));
};
const StyledList = styled.ul `
  line-height: 3.2rem;
  margin-block-end: 1.6rem;
  margin-left: 2rem;
  list-style-type: ${({ $variant }) => {
    switch ($variant) {
        case 'bullet':
            return 'disc';
        default:
            return 'disc';
    }
}};
`;
const StyledLi = styled.li `
  margin-block-end: 0.8rem;
`;
