import React from 'react';
import styled, { css } from 'styled-components';
import { DismissButton } from './DismissButton';
export const Tag = ({ title, variant, icon, hoverableVariant, onDismiss }) => (React.createElement(StyledBackground, null,
    React.createElement(StyledTag, { "$variant": variant, "$hoverable": hoverableVariant },
        icon,
        title,
        onDismiss && React.createElement(DismissButton, { onClick: onDismiss }))));
const StyledTag = styled.div `
  position: relative;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  gap: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.grey.medium};
  border-radius: 5px;

  ${({ $variant, $hoverable, theme }) => {
    switch ($variant) {
        case 'color':
            return css `
          ${$hoverable
                ? `cursor: pointer;
          svg {
            fill: ${theme.colors.grey.dark};
            height: 2.4rem;
            max-width: 2.4rem;
          }
          &:hover {
            border: 2px solid ${theme.colors.success.dark};
          }
          &:hover svg {
            fill: ${theme.colors.success.dark};
          }
          &:active {
            border: 2px solid ${theme.colors.success.dark};
            background: ${theme.colors.success.light}3D;
          }
          &:active svg {
            fill: ${theme.colors.success.dark};
          })`
                : ` background: ${theme.colors.success.light}3D;
            border: 2px solid ${theme.colors.success.dark};
            svg {
              fill: ${theme.colors.success.dark};
              height: 2.4rem;
              max-width: 2.4rem;
                }`}
        `;
        default:
            return css `
          ${$hoverable
                ? `
          cursor: pointer;

          svg {
            fill: ${theme.colors.grey.dark};
            height: 2.4rem;
            max-width: 2.4rem;
          }
          &:hover {
            border: 2px solid ${theme.colors.grey.dark};
          }
          &:active {
            border: 2px solid ${theme.colors.primary.medium};
            background: ${theme.colors.background.medium};
          }
        `
                : ` background: ${theme.colors.background.medium};
            border: 2px solid ${theme.colors.grey.medium};
          svg {
            fill: ${theme.colors.grey.dark};
            height: 2.4rem;
            max-width: 2.4rem;
              }`}
        `;
    }
}}
`;
// this ensures the transparent colour of the tag is always the same
const StyledBackground = styled.div `
  background: ${({ theme }) => theme.colors.white};
  display: inline-block;
  border-radius: 5px;
`;
