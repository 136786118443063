import React from 'react';
import styled from 'styled-components';
import { Caption } from '../Typography';
export const HintText = ({ isError, children }) => (React.createElement(StyledDiv, null,
    React.createElement(StyledCaption, { "$isError": isError }, children)));
const StyledDiv = styled.div `
  position: absolute;
  left: 1.2rem;
  bottom: -1.6rem;
  z-index: 1;
`;
const StyledCaption = styled(Caption) `
  color: ${(p) => (p.$isError ? p.theme.colors.danger.dark : p.theme.colors.dark)};
  position: relative;
  padding: 0 0.4rem;
  &:after {
    content: ' ';
    background-color: #fff;
    width: 100%;
    height: 0.5rem;
    position: absolute;
    left: 0;
    top: 1rem;
    z-index: -1;
  }
`;
