import { createGlobalStyle } from 'styled-components';
import { normalize } from './normalizeCss';
import { reset } from './resetCss';
export const GlobalStyle = createGlobalStyle `
${normalize}
${reset}

  html {
    overflow-y: scroll; // prevents layoutshift by always showing scroll bar
    font-family: 'Montserrat', sans-serif;
    font-size: 62.5%; // sets 1px => 0.1rem
    background-color: ${({ theme }) => theme.colors.background.medium}
  }

  body {
    background-color: ${({ theme }) => theme.colors.background.medium};
    font-size: 20px;
    font-size: 2rem;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.colors.dark};
    overflow: visible !important; // prevents third-party libraries from setting overflow to hidden
  }

  p {
    margin-block-end: 1.6rem
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.black}
  }
  h1 {
    margin-block-end: 4rem;
  }
  h2 {
    margin-block-end: 2.4rem;
  }
  h3 {
    margin-block-end: 2.4rem;
  }
  h4, h5, h6 {
    margin-block-end: 1.6rem
  }

  a {
    color: ${({ theme }) => theme.colors.link.dark};
    text-decoration: none;
  }

  button:focus-visible, a:focus-visible, input:focus-visible, textarea:focus-visible {
    outline: #000 2px solid;
    outline-offset: 3px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 3px #fff;
  }

  [data-test='authenticator-error'] {
    font-weight: bold;
    color: #ffffff;
    box-shadow: none;
  }

  .sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }

  .icon {
    font-size: 2.4rem !important;
  }

  .icon-interior {
    fill: #ffffff;
  }

  .icon-exterior {
    fill: none;
  }

  .legacy-padding-top {
    padding-top:1rem;
  }
  .legacy-padding-bottom {
    padding-bottom:1rem;
  }
`;
