import styled from 'styled-components';
import { screen } from '..';
export const Hero = styled.h1.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  color: ${({ theme, color }) => color || theme.colors.dark};
  font-size: 3.2rem;
  line-height: 5.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-block-start: ${({ mt }) => mt || '0'};
  margin-block-end: ${({ mb }) => mb || '4.8rem'};
  @media ${screen.mediumUp} {
    font-size: 6rem;
    line-height: 7.8rem;
    margin-block-end: ${({ mb }) => mb || '6.4rem'};
  }
`;
export const H1 = styled.h1.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  color: ${({ theme, color }) => color || theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.h1.mobile};
  line-height: 3.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-block-start: ${({ mt }) => mt || '0'};
  margin-block-end: ${({ mb }) => mb || '2.4rem'};
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.h1.default};
    line-height: 4.8rem;
    margin-block-end: ${({ mb }) => mb || '4.0rem'};
  }
`;
export const H2 = styled.h2.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  color: ${({ theme, color }) => color || theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.h2.mobile};
  line-height: 3.2rem;
  font-weight: ${({ $semibold, theme }) => ($semibold ? theme.fontWeights.semibold : theme.fontWeights.bold)};
  margin-block-start: ${({ mt }) => mt || '0'};
  margin-block-end: ${({ mb }) => mb || '1.6rem'};
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.h2.default};
    line-height: 3.8rem;
    margin-block-end: ${({ mb }) => mb || '2.4rem'};
  }
`;
export const H3 = styled.h3.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  color: ${({ theme, color }) => color || theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.h3.mobile};
  line-height: 2.8rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-block-start: ${({ mt }) => mt || '0'};
  margin-block-end: ${({ mb }) => mb || '1.6rem'};
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.h3.default};
    line-height: 3.2rem;
    margin-block-end: ${({ mb }) => mb || '2.4rem'};
  }
`;
export const H4 = styled.h4.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  color: ${({ theme, color }) => color || theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSizes.h4.mobile};
  line-height: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-block-start: ${({ mt }) => mt || '0'};
  margin-block-end: ${({ mb }) => mb || '1.6rem'};
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.h4.default};
    line-height: 2.8rem;
  }
`;
