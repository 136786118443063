import React from 'react';
import styled from 'styled-components';
import { Caption } from '../Typography';
import { Required } from './Required';
export const Label = ({ label, id, required = false, hasError = false }) => {
    return (React.createElement(StyledLabelContainer, null,
        React.createElement(StyledLabel, { as: "label", htmlFor: id, "$hasError": hasError },
            label,
            required && React.createElement(Required, null))));
};
export const StyledLabelContainer = styled.div `
  position: absolute;
  top: -1.3rem;
  left: 1.2rem;
  z-index: 1;
`;
export const StyledLabel = styled(Caption) `
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  pointer-events: none;
  margin: 0;
  color: ${({ $hasError, theme }) => ($hasError ? theme.colors.danger.dark : theme.colors.dark)};
  position: relative;
  padding: 0 0.4rem;
  &:after {
    content: ' ';
    background-color: #fff;
    width: 100%;
    height: 0.5rem;
    position: absolute;
    left: 0;
    top: 0.6rem;
    z-index: -1;
  }
`;
