import styled, { css } from 'styled-components';
import { screen } from '..';
export const Body1 = styled.p.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  ${({ $semibold, mb, mt, theme, color }) => {
    return css `
      color: ${color || theme.colors.dark};
      font-size: ${theme.fontSizes.body1.mobile};
      line-height: ${theme.lineHeights.body1.mobile};
      font-weight: ${$semibold ? theme.fontWeights.semibold : theme.fontWeights.regular};
      margin-block-start: ${mt || '0'};
      margin-block-end: ${mb || '0.8rem'};
      @media ${screen.mediumUp} {
        font-size: ${theme.fontSizes.body1.default};
        line-height: ${theme.lineHeights.body1.default};
        margin-block-end: ${mb || '1.6rem'};
      }
    `;
}}
`;
export const Body2 = styled.p.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  ${({ theme, $semibold, color, mb, mt }) => {
    return css `
      color: ${color || theme.colors.dark};
      font-size: ${theme.fontSizes.body2.mobile};
      line-height: ${theme.lineHeights.body2.mobile};
      font-weight: ${$semibold ? theme.fontWeights.semibold : theme.fontWeights.regular};
      margin-block-end: ${mb || '0.8rem'};
      margin-block-start: ${mt || '0'};
      @media ${screen.mediumUp} {
        font-size: ${theme.fontSizes.body2.default};
        line-height: ${theme.lineHeights.body2.default};
      }
    `;
}}
`;
export const Caption = styled.p.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  ${({ theme, $semibold, color, mb, mt }) => {
    return css `
      color: ${color || theme.colors.dark};
      font-size: ${theme.fontSizes.caption.default};
      line-height: ${theme.lineHeights.caption.default};
      font-weight: ${$semibold ? theme.fontWeights.semibold : theme.fontWeights.regular};
      margin-block-end: ${mb || '0.8rem'};
      margin-block-start: ${mt || '0'};
    `;
}}
`;
export const ButtonText = styled.p.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mb', 'color'].includes(prop),
}) `
  color: ${({ theme, color }) => color || theme.colors.dark};
  margin-block-end: ${({ mb }) => mb || '0.8rem'};
  margin-block-start: ${({ mt }) => mt || '0'};
  font-size: ${({ theme }) => theme.fontSizes.buttonText.default};
  line-height: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
