import React from 'react';
import styled from 'styled-components';
import { useBreakpointValues, ButtonStyling, ArrowRightIcon, ButtonFocusStyling } from '..';
// use named function to make it work in testing library tests when there are nested components and styled components
// https://github.com/styled-components/styled-components/issues/1449
export function ButtonLink({ children, href, target, variant = 'primary', size = 'medium', fullWidth = false, isDisabled, iconBefore, iconAfter, className, }) {
    const iconAfterDefault = !(iconAfter || iconBefore) ? React.createElement(ArrowRightIcon, null) : iconAfter;
    return (React.createElement(StyledAnchor, { variant: variant, size: size, target: target, fullWidth: useBreakpointValues(fullWidth), disabled: isDisabled, iconBefore: iconBefore, iconAfter: iconAfterDefault, className: className, href: href },
        iconBefore,
        " ",
        children,
        " ",
        iconAfterDefault));
}
const StyledAnchor = styled.a.withConfig({
    shouldForwardProp: (prop) => !['variant', 'size', 'fullWidth', 'iconOnly', 'iconBefore', 'iconAfter'].includes(prop),
}) `
  &:focus {
    ${ButtonFocusStyling}
  }
  ${ButtonStyling};
`;
