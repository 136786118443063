import styled from 'styled-components';
import { screen } from '..';
export const Card = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant', 'mb'].includes(prop),
}) `
  width: 100%;
  background: white;
  padding: 1.6rem;
  @media ${screen.mediumUp} {
    padding: 2.4rem;
  }
  ${({ variant, theme }) => (variant === 'border' ? 'box-shadow: unset' : `box-shadow: ${theme.boxShadows.one}`)};
  border-radius: 8px;
  border: ${({ variant, theme }) => (variant === 'border' ? `1px solid ${theme.colors.grey.light}` : 'none')};
  margin-bottom: ${({ mb = 0 }) => mb};
`;
