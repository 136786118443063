import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { linkStyles } from '../_styles/linkStyles';
export const LinkInternal = ({ children, href, className, size = 'default', colour = 'default', target = '_self', onClick, }) => {
    return (React.createElement(Link, { href: href, target: target, className: className },
        React.createElement(StyledSpan, { size: size, colour: colour, onClick: onClick }, children)));
};
const StyledSpan = styled.span.withConfig({
    shouldForwardProp: (prop) => !['size', 'colour'].includes(prop),
}) `
  ${linkStyles}
`;
