import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { screen } from '../_utils';
export const ModalAriaLabelledBy = 'modalTitle';
export const ModalAriaDescribedBy = 'modalBody';
export const BlankModal = ({ open, onCloseCallback: onClose, fullScreen = false, className, children, }) => {
    const modalRef = useRef(null);
    const previouslyFocusedElement = useRef(null);
    const handleClose = useCallback((reason) => {
        if (onClose)
            onClose(reason);
    }, [onClose]);
    /**
     * get the previously focused element and focus on it when the modal closes
     */
    useEffect(() => {
        var _a;
        if (open) {
            if (!previouslyFocusedElement.current) {
                previouslyFocusedElement.current = document.activeElement;
            }
        }
        else if (previouslyFocusedElement.current) {
            (_a = previouslyFocusedElement.current) === null || _a === void 0 ? void 0 : _a.focus();
            previouslyFocusedElement.current = null;
        }
    }, [open]);
    /**
     * trap focus inside the modal
     */
    useEffect(() => {
        const queryFocusableElements = (element) => {
            const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            if (element) {
                return Array.from(element.querySelectorAll(focusableElements));
            }
            return [];
        };
        const focusableElementsArray = queryFocusableElements(modalRef.current);
        const firstFocusableEl = focusableElementsArray[0] || null;
        const lastFocusableEl = focusableElementsArray[focusableElementsArray.length - 1] || null;
        const handleKeyDown = (event) => {
            if (event.key === 'Tab') {
                if (event.target === lastFocusableEl && !event.shiftKey) {
                    firstFocusableEl.focus();
                    event.preventDefault();
                }
                else if (event.target === firstFocusableEl && event.shiftKey) {
                    lastFocusableEl.focus();
                    event.preventDefault();
                }
            }
            else if (event.key === 'Escape') {
                handleClose('escapeKeyDown');
            }
        };
        if (open) {
            document.addEventListener('keydown', handleKeyDown);
            if (firstFocusableEl) {
                firstFocusableEl.focus();
            }
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, handleClose]);
    if (!open) {
        return null;
    }
    return (React.createElement(StyledOverlay, { "$open": open, onClick: () => handleClose('backdropClick'), "$fullScreen": fullScreen },
        React.createElement(StyledBox, { "aria-labelledby": ModalAriaLabelledBy, "aria-describedby": ModalAriaDescribedBy, role: "dialog", "aria-modal": "true", "$fullScreen": fullScreen, className: className, onClick: (e) => e.stopPropagation(), ref: modalRef }, children)));
};
const StyledOverlay = styled.div `
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  inset: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary.dark}A3;
  backdrop-filter: blur(24px);

  ${({ $fullScreen }) => !$fullScreen &&
    `
  @media ${screen.smallUp} {
    padding: 2.4rem
  }`}
`;
const StyledBox = styled.div `
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  ${({ $fullScreen }) => !$fullScreen &&
    `
  @media ${screen.smallUp} {
    height: auto;
    max-height: min(100%, 80rem);
    min-height: 20rem;
    max-width: 71.8rem;
    min-width: 55.2rem;
    border-radius: 0.8rem;
  }
  `}
  @media ${screen.smallUp} {
    padding: 2.4rem;
  }
`;
