import React from 'react';
import styled from 'styled-components';
import { linkStyles } from '../_styles/linkStyles';
export const Link = ({ children, href, className, target = '_self', onClick, size = 'default', colour = 'default', download = false, }) => {
    return (React.createElement(StyledLink, { href: href, target: target, size: size, colour: colour, className: className, onClick: onClick, download: download }, children));
};
const StyledLink = styled.a.withConfig({
    shouldForwardProp: (prop) => !['size', 'colour'].includes(prop),
}) `
  ${linkStyles}
`;
